import { ISustenanceAllowance } from './../../../../_models/sustenance-allowance/ISustenanceAllowance';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sustenance-allowance',
  templateUrl: './sustenance-allowance.component.html',
  styleUrls: ['./sustenance-allowance.component.css']
})
export class SustenanceAllowanceComponent implements OnInit {
  @Input() sustenanceAllowance: ISustenanceAllowance;

  constructor() { }

  ngOnInit(): void {
  }

}
