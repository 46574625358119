import { IAccelerationProgramFinal } from './../../../../_models/acceleration-program-final/IAccelerationProgramFinal';
import { IAccelerationProgram } from './../../../../_models/acceleration-program/IAccelerationProgram';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-acceleration-program-final-view',
  templateUrl: './acceleration-program-final-view.component.html',
  styleUrls: ['./acceleration-program-final-view.component.css']
})
export class AccelerationProgramFinalViewComponent implements OnInit {
  @Input() accelerationProgramFinal: IAccelerationProgramFinal;

  constructor() { }

  ngOnInit(): void {
  }

}
