import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private hideGhost = new BehaviorSubject<boolean | null>(true);
  hideGhostChanges$ = this.hideGhost.asObservable();

  private title = new BehaviorSubject<string | null>("iNDEXTb - Industrial Extension Bureau");
  titleChanges$ = this.title.asObservable();

  changehideGhost(hideGhost: boolean | null): void {
    this.hideGhost.next(hideGhost);
  }

  changeTitle(title: string | null, description?: string | null): void {
    if (title) {
      this.titleService.setTitle(title + " - iNDEXTb - Industrial Extension Bureau");
    }
    else {
      this.titleService.setTitle("iNDEXTb - Industrial Extension Bureau");
    }
    this.metaTagService.updateTag(
      { name: 'description', content: description ? description : "iNDEXTb - investment promotion organization of Gujarat Government to accelerate social-economic upliftment and industrial development in Gujarat." }
    );
  }

  constructor(
    private titleService: Title,
    private metaTagService: Meta) {

  }
}
