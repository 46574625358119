import { OtherInstAuthGuard } from './services/other-inst-guard.service';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { AccelerationProgramFinalViewComponent } from './components/views/acceleration-program-final-view/acceleration-program-final-view.component';
import { ShareFeedbackComponent } from './components/share-feedback/share-feedback.component';
import { OnlynumberDirective } from './_directives/only-number.directive';
import { StepperComponent } from './components/stepper/stepper.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { icons } from './_common/bootstrap-icons';
import { CustomAdapter, CustomDateParserFormatter } from './_common/custom-date-formatter';
import { NgbTimeStringAdapter } from './_common/custom-time-formatter';

import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiModule } from './../api/api.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CountdownModule } from 'ngx-countdown';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { AuthGuard } from './services/auth-guard.service';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { UserAuthGuard } from './services/user-guard.service';
import { NodalAuthGuard } from './services/nodal-guard.service';
import { IcAuthGuard } from './services/ic-guard.service';
import { ScrutinyAuthGuard } from './services/scrutiny-guard.service';
import { SlecAuthGuard } from './services/slec-guard.service';
import { IndustryCommissionAuthGuard } from './services/industrycommission-guard.service';
import { MentorAuthGuard } from './services/mentor-guard.service';

import { EditorConfigService } from './services/angular-editor.service';
import { HeaderService } from './services/header.service';
import { LoaderService } from './services/loader.service';

import { MyInputDirective } from './_directives/my-input.directive';
import { LazyImgDirective } from './_directives/lazy-img.directive';

import { TruncatePipe } from './pipes/truncate.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

import { PaginationComponent } from './components/pagination/pagination.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MyErrorComponent } from './components/my-error/my-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserBarComponent } from './components/user-bar/user-bar.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchComponent } from './components/search/search.component';
import { TwitterComponent } from './components/twitter/twitter.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { PolicyListComponent } from './components/policy-list/policy-list.component';
import { AssistanceListComponent } from './components/assistance-list/assistance-list.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { SocialConnectComponent } from './components/social-connect/social-connect.component';
import { HelpComponent } from '../shared/components/help/help.component';
import { CompanyViewComponent } from './components/views/company-view/company-view.component';
import { SeedSupportViewComponent } from './components/views/seed-support-view/seed-support-view.component';
import { SkillDevelopmentViewComponent } from './components/views/skill-development-view/skill-development-view.component';
import { AccelerationProgramViewComponent } from './components/views/acceleration-program-view/acceleration-program-view.component';
import { OrganizingAccelerationProgramViewComponent } from './components/views/organizing-acceleration-program-view/organizing-acceleration-program-view.component';
import { OrganizingEventViewComponent } from './components/views/organizing-event-view/organizing-event-view.component';
import { SocialImpactViewComponent } from './components/views/social-impact-view/social-impact-view.component';
import { NodalFormViewComponent } from './components/views/nodal-form-view/nodal-form-view.component';
import { SchemeViewComponent } from './components/views/scheme-view/scheme-view.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CompanyNodalViewComponent } from './components/views/company-nodal-view/company-nodal-view.component';
import { SchemeDocsViewComponent } from './components/views/scheme-docs-view/scheme-docs-view.component';
import { SustenanceAllowanceComponent } from './components/views/sustenance-allowance/sustenance-allowance.component';
import { MentoringStartupViewComponent } from './components/views/mentoring-startup-view/mentoring-startup-view.component';
import { OrganizingEventFinalViewComponent } from './components/views/organizing-event-final-view/organizing-event-final-view.component';
import { NgxPrintModule } from 'ngx-print';
import { NodalRecommendationViewComponent } from './components/views/nodal-recommendation-view/nodal-recommendation-view.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgPipesModule } from 'ngx-pipes';
import { MentorFormViewComponent } from './components/views/mentor-form-view/mentor-form-view.component';
import { NodalInstituteComponent } from './components/certificates/nodal-institute/nodal-institute.component';
import { I18nModule } from '../i18n/i18n.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OtherInstOrgAcceProgViewComponent } from './components/views/other-inst-org-acce-prog-view/other-inst-org-acce-prog-view.component';
import { OtherInstOrgAcceProgFinalViewComponent } from './components/views/other-inst-org-acce-prog-final-view/other-inst-org-acce-prog-final-view.component';
import { GrievanceFormViewComponent } from './components/views/grievance-form-view/grievance-form-view.component';
import { GrievanceFormComponent } from './components/views/grievance-form-view/grievance-form-remark/grievance-form-remark.component';
import { InvesterAuthGuard } from './services/invester-guard.service';
import { InvesterFormViewComponent } from './components/views/invester-form-view/invester-form-view.component';
import { MartInfoViewComponent } from './components/views/mart-info-view/mart-info-view.component';


@NgModule({
  declarations: [
    MyInputDirective,
    OnlynumberDirective,
    PaginationComponent,
    LazyImgDirective,
    NoAccessComponent,
    NotFoundComponent,
    MyErrorComponent,
    LoaderComponent,
    UserBarComponent,
    FooterComponent,
    TruncatePipe,
    SafeUrlPipe,
    MenuComponent,
    MenuItemComponent,
    SearchComponent,
    TwitterComponent,
    GalleryComponent,
    DialogComponent,
    PolicyListComponent,
    AssistanceListComponent,
    StepperComponent,
    ChatbotComponent,
    FooterBarComponent,
    SocialConnectComponent,
    HelpComponent,
    CompanyViewComponent,
    SeedSupportViewComponent,
    SkillDevelopmentViewComponent,
    AccelerationProgramViewComponent,
    AccelerationProgramFinalViewComponent,
    OrganizingAccelerationProgramViewComponent,
    OrganizingEventViewComponent,
    SocialImpactViewComponent,
    NodalFormViewComponent,
    SchemeViewComponent,
    CompanyNodalViewComponent,
    SchemeDocsViewComponent,
    ShareFeedbackComponent,
    SustenanceAllowanceComponent,
    MentoringStartupViewComponent,
    OrganizingEventFinalViewComponent,
    NodalRecommendationViewComponent,
    ChangePasswordComponent,
    MentorFormViewComponent,
    NodalInstituteComponent,
    SelectLanguageComponent,
    OtherInstOrgAcceProgViewComponent,
    OtherInstOrgAcceProgFinalViewComponent,
    GrievanceFormViewComponent,
    GrievanceFormComponent,
    InvesterFormViewComponent,
    MartInfoViewComponent
  ],
  imports: [
    CommonModule,
    ApiModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxBootstrapIconsModule.pick(icons),
    AngularEditorModule,
    InfiniteScrollModule,
    ToastrModule.forRoot(),
    SlickCarouselModule,
    InlineSVGModule,
    CountdownModule,
    CdkStepperModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSliderModule,
    NgxPrintModule,
    NgxCaptchaModule,
    NgPipesModule,
    I18nModule,
    NgxSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxBootstrapIconsModule,
    AngularEditorModule,
    InfiniteScrollModule,
    ToastrModule,
    SlickCarouselModule,
    InlineSVGModule,
    CountdownModule,
    CdkStepperModule,
    NgxSliderModule,
    NgMultiSelectDropDownModule,
    NgPipesModule,
    I18nModule,
    NgxSpinnerModule,

    OnlynumberDirective,
    MyInputDirective,
    NgxPrintModule,
    NgxCaptchaModule,

    TruncatePipe,
    SafeUrlPipe,
    PaginationComponent,
    LazyImgDirective,
    NoAccessComponent,
    NotFoundComponent,
    MyErrorComponent,
    LoaderComponent,
    UserBarComponent,
    FooterComponent,
    MenuComponent,
    MenuItemComponent,
    TwitterComponent,
    GalleryComponent,
    DialogComponent,
    PolicyListComponent,
    AssistanceListComponent,
    StepperComponent,
    ChatbotComponent,
    FooterBarComponent,
    SocialConnectComponent,
    HelpComponent,
    CompanyViewComponent,
    SeedSupportViewComponent,
    SkillDevelopmentViewComponent,
    AccelerationProgramViewComponent,
    AccelerationProgramFinalViewComponent,
    OrganizingAccelerationProgramViewComponent,
    OrganizingEventViewComponent,
    SocialImpactViewComponent,
    NodalFormViewComponent,
    SchemeViewComponent,
    CompanyNodalViewComponent,
    SchemeDocsViewComponent,
    ShareFeedbackComponent,
    SustenanceAllowanceComponent,
    MentoringStartupViewComponent,
    OrganizingEventFinalViewComponent,
    NodalRecommendationViewComponent,
    ChangePasswordComponent,
    MentorFormViewComponent,
    SelectLanguageComponent,
    OtherInstOrgAcceProgViewComponent,
    OtherInstOrgAcceProgFinalViewComponent,
    GrievanceFormViewComponent,
    InvesterFormViewComponent,
    MartInfoViewComponent
  ],
  providers: [
    DatePipe,
    AuthGuard,
    AdminAuthGuard,
    UserAuthGuard,
    InvesterAuthGuard,
    NodalAuthGuard,
    OtherInstAuthGuard,
    IcAuthGuard,
    ScrutinyAuthGuard,
    SlecAuthGuard,
    IndustryCommissionAuthGuard,
    MentorAuthGuard,
    EditorConfigService,
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    HeaderService,
    LoaderService
  ]
})
export class SharedModule { }
