import { IGrievance } from './../../../../_models/grievance/IGrievance';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GrievanceService } from 'src/app/api/services/grievance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IPaged } from 'src/app/_models/common/IPaged';
import { IKeyValuePair } from 'src/app/_models/common/common';
import { MediaFileService } from 'src/app/api/services/media-file.service';

@Component({
  selector: 'app-grievance-form-view',
  templateUrl: './grievance-form-view.component.html',
  styleUrls: ['./grievance-form-view.component.css']
})

export class GrievanceFormViewComponent implements OnInit {
  @Input() grievance: IGrievance;
  private readonly PAGE_SIZE = 10;
  queryTypes: IKeyValuePair[];

  queryResult: IPaged = {
    pageNumber: 0,
    items: [],
    totalRows: 0,
    pageSize: this.PAGE_SIZE
  };
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: GrievanceService,
    private modalService: NgbModal,
    private mediaFileService: MediaFileService
  ) { }

  query: any = {
    page: 1,
    pageSize: this.PAGE_SIZE,
    sort: 'Id',
    sortDir: 'Descending',
    subject: '',
    ticketNo: '',
    queryTypeId: '',
  };

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.query.page = isNaN(params.page) ? 1 : params.page;
        this.query.sort = params.sort;
        this.query.sortDir = params.sortDir;
        this.query.subject = this.query.subject ? this.query.subject : "";
        this.query.ticketNo = this.query.ticketNo ? this.query.ticketNo : "";
        this.query.queryTypeId = this.query.queryTypeId ? this.query.queryTypeId : "";
        this.populateGrievances();
      });
  }

  open(content, v: IGrievance) {
    this.grievance = v;
    this.modalService.open(content, { size: 'xl' });
  }
  reloadGrievances() {
    this.populateGrievances();
    this.closeModal();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
  
    private populateGrievances() {
    this.service.getAll(this.query)
      .subscribe((response: IPaged) => {
        if (response) {
          this.queryResult = response;
        }
      });
  }
  downloaddoc(id) {
    debugger
    if (id) {
      this.mediaFileService.checkfileexist(id).subscribe((response: any) => {
        if (response) {
          this.mediaFileService.getfiledown(id).subscribe((data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = downloadURL;
            link.click();
          });
        }
      });
    }
  }

}
