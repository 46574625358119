import { IOrganizingEvent } from './../../../../_models/organizing-event/IOrganizingEvent';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizing-event-view',
  templateUrl: './organizing-event-view.component.html',
  styleUrls: ['./organizing-event-view.component.css']
})
export class OrganizingEventViewComponent implements OnInit {
  @Input() organizingEvent: IOrganizingEvent;

  constructor() { }

  ngOnInit(): void {
  }

}
