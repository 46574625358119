import { INodalRecommendation } from './../../../../_models/nodal/INodalRecommendation';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nodal-recommendation-view',
  templateUrl: './nodal-recommendation-view.component.html',
  styleUrls: ['./nodal-recommendation-view.component.css']
})
export class NodalRecommendationViewComponent implements OnInit {
  @Input() nodalRecommendation: INodalRecommendation;

  constructor() { }

  ngOnInit(): void {
  }

}
