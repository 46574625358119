import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../api/services/auth.service";

@Injectable()
export class AdminAuthGuard implements CanActivate {

  constructor(private route: Router, private authService: AuthService) { }

  canActivate() {
    let user = this.authService.currentUser;
    let roles = this.authService.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if (user) {
      var admin = typeof roles == 'object' ? roles.filter(x => x == "admin") : roles;
      if (admin && (admin == "admin")) {
        return true;
      }
    }

    this.route.navigate(['/no-access']);
    return false;
  }


}
