import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SearchService extends DataService {
  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/search', httpClient, toastrService);
  }

  searchKeyword(term: string) {
    if (term === '' || term.length <= 2) {
      return of([]);
    }

    return this.httpClient.get(this.baseUrl + 'api/search?term=' + term)
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }
}