import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DisbursementFormService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/disbursement-form', httpClient, toastrService);
  }

  getView(id?: number, schemeId?: number) {
    let filter = {
      id: id,
      schemeId: schemeId
    }

    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/view' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getLogged() {
    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllStartupList(filter) {
    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/get-disbusment-startup-list' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalList(filter) {
    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/disbusment-nodal-list' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllDisbursementForms() {
    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/all')
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirm(resource) {
    return this.httpClient.post(this.baseUrl + 'api/disbursement-form/confirm', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  approve(resource) {
    return this.httpClient.post(this.baseUrl + 'api/disbursement-form/approve', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  forward(resource) {
    return this.httpClient.post(this.baseUrl + 'api/disbursement-form/forward', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllStartups() {
    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/startup-schemes')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getByIcAppFormId(icAppFormId?: number) {
    let filter = {
      icAppFormId: icAppFormId
    }

    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/get-by-icappform-id' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
  getByIcAppFormIdList(icAppFormId?: number) {
    let filter = {
      icAppFormId: icAppFormId
    }

    return this.httpClient.get(this.baseUrl + 'api/disbursement-form/get-by-icappform-id-list' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
}