import { QuarterlyReportStartupService } from './services/quarterly-report-startup.service';
import { OtherInstSchemeDocService } from './services/other-inst-scheme-doc.service';
import { OtherInstAppFormService } from './services/other-inst-app-form.service';
import { OtherInstService } from './services/other-inst.service';
import { SsipInstituteService } from './services/ssip-institute.service';
import { OtherInstOrgAcceProgFinalService } from './services/other-inst-org-acce-prog-final.service';
import { OtherInstOrgAcceProgService } from './services/other-inst-org-acce-prog.service';
import { ReportService } from './services/report.service';
import { KnowYourApprovalNewService } from './services/know-your-approval-new.service';
import { StartupIndiaService } from './services/startup-india.service';
import { SelfCertificationService } from './services/self-certification.service';
import { DashboardService } from './services/dashboard.service';
import { SchemeService } from './services/scheme.service';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppErrorHandler } from './_common/app-error-handler';
import { DataService } from './services/data.service';

import { CommonService } from './services/common.service';
import { MediaFileService } from './services/media-file.service';
import { AuthService } from './services/auth.service';
import { ProfileService } from './services/profile.service';
import { SearchService } from './services/search.service';

import { FAQService } from './services/faq.service';
import { FAQCategoryService } from './services/faq-category.service';
import { CmsPageService } from './services/cms-page.service';
import { NoticeBoardService } from './services/notice-board.service';
import { NoticeBoardCategoryService } from './services/notice-board-category.service';
import { MenuService } from './services/menu.service';
import { MenuContentService } from './services/menu-content.service';
import { FeedbackService } from './services/feedback.service';
import { GalleryItemsService } from './services/gallery-items.service';
import { GalleryService } from './services/gallery.service';
import { RegistrationService } from './services/registration.service';
import { PolicyService } from './services/policy.service';
import { PolicyCategoryService } from './services/policy-category.service';
import { IcAppFormService } from './services/ic-app-form.service';
import { MentorService } from './services/mentor.service';
import { NodalService } from './services/nodal.service';
import { RecallService } from './services/recall.service';
import { SlecAppFormService } from './services/slec-app-form.service';
import { ScrutinyAppFormService } from './services/scrutiny-app-form.service';
import { EventParticipantService } from './services/event-participant.service';
import { NodalDocService } from './services/nodal-doc.service';
import { NodalRecommendationService } from './services/nodal-recommendation.service';
import { NodalAppFormService } from './services/nodal-app-form.service';
import { GrievanceService } from './services/grievance.service';
import { SlecMinuteService } from './services/slec-minute.service';
import { EventService } from './services/event.service';
import { SeedSupportDocService } from './services/seed-support-doc.service';
import { SeedSupportService } from './services/seed-support.service';
import { AppFormService } from './services/app-form.service';
import { CompanyInfoService } from './services/company-info.service';
import { CompanyService } from './services/company.service';
import { MeetingService } from './services/meeting.service';
import { OrganizingAccelerationProgramService } from './services/organizing-acceleration-program.service';
import { OrganizingEventService } from './services/organizing-event.service';
import { SocialImpactService } from './services/social-impact.service';
import { AccelerationProgramService } from './services/acceleration-program.service';
import { SkillDevelopmentService } from './services/skill-development.service';
import { NodalProfileDocService } from './services/nodal-profile-doc.service';
import { IndCommAppFormService } from './services/indcomm-app-form.service';
import { NodalSchemeDocService } from './services/nodal-scheme-doc.service';
import { MentoringStartupService } from './services/mentoring-startup.service';
import { NodalSchemeAppFormService } from './services/nodal-scheme-app-form.service';
import { SustenanceAllowanceService } from './services/sustenance-allowance.service';
import { TagService } from './services/tag.service';
import { TagGroupService } from './services/tag-group.service';
import { OperationTypeService } from './services/operation-type.service';
import { KnowYourApprovalService } from './services/know-your-approval.service';
import { OrganizingEventFinalService } from './services/organizing-event-final.service';
import { EntCellService } from './services/ent-cell.service';
import { PartnershipService } from './services/partnership.service';
import { AccelerationProgramFinalService } from './services/acceleration-program-final.service';
import { StartupRequestService } from './services/startup-request.service';
import { MonthlyReportStartupService } from './services/monthly-report-startup.service';
import { NodalRequestService } from './services/nodal-request.service';
import { DisbursementFormService } from './services/disbursement-form.service';
import { DisbursementNodalService } from './services/disbursement-nodal.service';
import { DisbursementStartupService } from './services/disbursement-startup.service';
import { InvesterService } from './services/invester.service';
import { InvesterMeetingService } from './services/invester-meeting.service';
import { MartInfoService } from './services/mart-info.service';
import { InvesterStartupMeetingService } from './services/invester-startup-meeting.service';
import { StartuptoMentorMeetingService } from './services/startup-to-mentor-meeting.service';

@NgModule({
    providers: [
        DataService,
        AuthService,
        { provide: 'API_BASE_URL', useValue: '/' },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        CommonService,
        ProfileService,
        MediaFileService,
        SearchService,
        FAQCategoryService,
        FAQService,
        CmsPageService,
        MenuContentService,
        MenuService,
        NoticeBoardCategoryService,
        NoticeBoardService,
        FeedbackService,
        GalleryService,
        GalleryItemsService,
        RegistrationService,
        PolicyService,
        PolicyCategoryService,
        NodalService,
        MentorService,
        CompanyService,
        CompanyInfoService,
        AppFormService,
        SeedSupportService,
        SeedSupportDocService,
        EventService,
        SlecMinuteService,
        GrievanceService,
        NodalAppFormService,
        NodalRecommendationService,
        NodalDocService,
        EventParticipantService,
        IcAppFormService,
        ScrutinyAppFormService,
        SlecAppFormService,
        RecallService,
        SkillDevelopmentService,
        AccelerationProgramService,
        AccelerationProgramFinalService,
        SocialImpactService,
        OrganizingEventService,
        OrganizingAccelerationProgramService,
        MeetingService,
        NodalProfileDocService,
        IndCommAppFormService,
        SustenanceAllowanceService,
        NodalSchemeAppFormService,
        MentoringStartupService,
        KnowYourApprovalService,
        OperationTypeService,
        TagGroupService,
        TagService,
        OrganizingEventFinalService,
        NodalSchemeDocService,
        EntCellService,
        PartnershipService,
        SchemeService,
        DashboardService,
        SelfCertificationService,
        StartupIndiaService,
        KnowYourApprovalNewService,
        ReportService,
        OtherInstService,
        OtherInstAppFormService,
        OtherInstOrgAcceProgService,
        OtherInstOrgAcceProgFinalService,
        OtherInstSchemeDocService,
        SsipInstituteService,
        StartupRequestService,
        MonthlyReportStartupService,
        NodalRequestService,
        QuarterlyReportStartupService,
        DisbursementFormService,
        DisbursementNodalService,
        DisbursementStartupService,
        InvesterService,
        InvesterMeetingService,
        InvesterStartupMeetingService,
        MartInfoService,
        StartuptoMentorMeetingService
    ]
})

export class ApiModule { }
