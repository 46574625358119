import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
  },
  {
    path: 'policy',
    data: { breadcrumb: 'Policy' },
    loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'contact-us',
    data: { breadcrumb: 'Contact Us' },
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'notice-board',
    data: { breadcrumb: 'Notice Board' },
    loadChildren: () => import('./notice-board/notice-board.module').then(m => m.NoticeBoardModule)
  },
  {
    path: 'help',
    data: { breadcrumb: 'Help' },
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'company',
    data: { breadcrumb: 'Company' },
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'nodal',
    data: { breadcrumb: 'Nodal' },
    loadChildren: () => import('./nodal/nodal.module').then(m => m.NodalModule)
  },
  {
    path: 'mentor',
    data: { breadcrumb: 'Mentor' },
    loadChildren: () => import('./mentor/mentor.module').then(m => m.MentorModule)
  },
  {
    path: 'grievance',
    data: { breadcrumb: 'Grievance' },
    loadChildren: () => import('./grievance/grievance.module').then(m => m.GrievanceModule)
  },
  {
    path: 'ic',
    data: { breadcrumb: 'IC' },
    loadChildren: () => import('./ic/ic.module').then(m => m.IcModule)
  },
  {
    path: 'scrutiny',
    data: { breadcrumb: 'Scrutiny' },
    loadChildren: () => import('./scrutiny/scrutiny.module').then(m => m.ScrutinyModule)
  },
  {
    path: 'slec',
    data: { breadcrumb: 'Slec' },
    loadChildren: () => import('./slec/slec.module').then(m => m.SlecModule)
  },
  {
    path: 'indcomm',
    data: { breadcrumb: 'IndComm' },
    loadChildren: () => import('./industrycommission/industrycommission.module').then(m => m.IndustryCommissioncModule)
  },
  {
    path: 'invester',
    data: { breadcrumb: 'Invester' },
    loadChildren: () => import('./invester/invester.module').then(m => m.InvesterModule)
  },
  
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    data: { breadcrumb: "Events" }
  },
  {
    path: 'gallery',
    loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule),
    data: { breadcrumb: "Gallery" }
  },
  {
    path: 'know-your-approval',
    loadChildren: () => import('./know-your-approval/know-your-approval.module').then(m => m.KnowYourApprovalModule),
    data: { breadcrumb: "Know Your Approval" }
  },
  {
    path: 'acceleration-programs',
    loadChildren: () => import('./acceleration-program/acceleration-program.module').then(m => m.AccelerationProgramModule),
    data: { breadcrumb: "Acceleration Programs" }
  },
  {
    path: 'photo-gallery',
    loadChildren: () => import('./photo-gallery/photo-gallery.module').then(m => m.PhotoGalleryModule),
    data: { breadcrumb: "Photo Gallery" }
  },
  {
    path: 'startup-lifecycle',
    loadChildren: () => import('./startup-lifecycle/startup-lifecycle.module').then(m => m.StartupLifecycleModule),
    data: { breadcrumb: "Startup Lifecycle" }
  },
  {
    path: 'venture-capital',
    loadChildren: () => import('./venture-capital/venture-capital.module').then(m => m.VentureCapitalModule),
    data: { breadcrumb: "Venture Capital" }
  },
  {
    path: 'scheme-for-assistance',
    loadChildren: () => import('./scheme-for-assistance/scheme-for-assistance.module').then(m => m.SchemeForAssistanceModule),
    data: { breadcrumb: "Scheme For Assistance" }
  },
  {
    path: 'grievance-mechanism',
    loadChildren: () => import('./grievance-mechanism/grievance-mechanism.module').then(m => m.GrievanceMechanismModule),
    data: { breadcrumb: "Grievance Mechanism" }
  },
  {
    path: 'intellectual-property',
    loadChildren: () => import('./intellectual-property/intellectual-property.module').then(m => m.IntellectualPropertyModule),
    data: { breadcrumb: "Intellectual Property" }
  },
 
  {
    path: 'enterpreneurship-cells',
    loadChildren: () => import('./entrepreneurship-cells/entrepreneurship-cells.module').then(m => m.EntrepreneurshipCellsModule),
    data: { breadcrumb: "Entrepreneurship Cells" }
  },
  {
    path: 'partnerships',
    loadChildren: () => import('./partnerships/partnerships.module').then(m => m.PartnershipsModule),
    data: { breadcrumb: "Partnerships" }
  },
  {
    path: 'gujarat-cell',
    loadChildren: () => import('./gujarat-cell/gujarat-cell.module').then(m => m.GujaratCellModule),
    data: { breadcrumb: "Gujarat Cell" }
  },
  {
    path: 'other-inst',
    loadChildren: () => import('./other-inst/other-inst.module').then(m => m.OtherInstModule),
    data: { breadcrumb: "Other Institute" }
  },
  
  { path: '**', redirectTo: '/404' },
  { path: '404', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
