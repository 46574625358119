import { IAccelerationProgram } from './../../../../_models/acceleration-program/IAccelerationProgram';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-acceleration-program-view',
  templateUrl: './acceleration-program-view.component.html',
  styleUrls: ['./acceleration-program-view.component.css']
})
export class AccelerationProgramViewComponent implements OnInit {
  @Input() accelerationProgram: IAccelerationProgram;

  constructor() { }

  ngOnInit(): void {
  }

}
