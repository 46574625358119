import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'df-messenger',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript('https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1').then(data => {
    }).catch(error => console.log(error));
  }

  public loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const body = <HTMLDivElement>document.body;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = false;
      script.defer = true;

      script.onload = () => {
        resolve({ loaded: true, status: 'Loaded' });
      };

      script.onerror = (error: any) => resolve({ loaded: false, status: 'Loaded' });
      body.appendChild(script);
    });
  }

}
