import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';

@Injectable()
export class RegistrationService extends DataService {
  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authService: AuthService, toastrService: ToastrService) {
    super(baseUrl + 'api/registrations', httpClient, toastrService);
  }

  getApproveuser() {
    return this.httpClient.get(this.baseUrl + 'api/registrations/approve-user')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getLogged() {
    return this.httpClient.get(this.baseUrl + 'api/registrations/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }
  uploadJobseekerList(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let token = this.authService.getToken;
    return this.httpClient
      .post(this.baseUrl + 'api/registrations' + "/upload-jobseeker-list-xls", formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
      .pipe(catchError(this.handleError.bind(this)));
  }
}