import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Injectable } from '@angular/core';

@Injectable()
export class EditorConfigService {

    getOption(): AngularEditorConfig {
        return {
            editable: true,
            spellcheck: true,
            height: 'auto',
            minHeight: '20rem',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: 'Enter text here...',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            sanitize: false,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [
                [
                    'undo',
                    'redo',
                    //'bold',
                    //'italic',
                    //'underline',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    'justifyLeft',
                    'justifyCenter',
                    'justifyRight',
                    'justifyFull',
                    'indent',
                    'outdent',
                    //'insertUnorderedList',
                    //'insertOrderedList',
                    //'heading',
                    'fontName'
                ],
                [
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'customClasses',
                    //'link',
                    //'unlink',
                    'insertImage',
                    'insertVideo',
                    'insertHorizontalRule',
                    'removeFormat',
                    //'toggleEditorMode'
                ]
            ]
        };
    }
}