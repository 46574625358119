import { ICompany } from './../../../../_models/company/ICompany';
import { CompanyService } from './../../../../api/services/company.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.css']
})
export class CompanyViewComponent implements OnInit {
  @Input() userId;

  company: any;
  register: any;

  constructor(
    private service: CompanyService
  ) { }

  ngOnInit(): void {
    this.getCompanyData();
  }

  getCompanyData() {
    this.service.get(this.userId).subscribe((response: any) => {
      if (response) {
        this.company = response;
        if (this.company && this.company.user && this.company.user.registration) {
          this.register = this.company.user.registration;
          console.log(this.company);
        }
      }
    })
  }
}
