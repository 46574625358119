import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class OtherInstOrgAcceProgFinalService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/other-inst-org-acce-prog-finals', httpClient, toastrService);
  }

  getExistData(id) {
    return this.httpClient.get(this.baseUrl + 'api/other-inst-org-acce-prog-finals/exist?id=' + id)
      .pipe(catchError(this.handleError.bind(this)))
  }

}
