import { ISeedSupport } from './../../../../_models/scheme/ISeedSupport';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-seed-support-view',
  templateUrl: './seed-support-view.component.html',
  styleUrls: ['./seed-support-view.component.css']
})
export class SeedSupportViewComponent implements OnInit {
  @Input() seedSupport: ISeedSupport;

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
