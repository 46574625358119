import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ic-incubator-profile-c',
  templateUrl: './ic-incubator-profile-c.component.html',
  styleUrls: ['./ic-incubator-profile-c.component.css']
})
export class IcIncubatorProfileCComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
