import { SearchService } from './../../../api/services/search.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  items: any[] = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private service: SearchService) { }

  ngOnInit(): void {
  }

  keyword = new FormControl();
  searchForm: FormGroup = this.fb.group({
    keyword: this.keyword
  });

  search() {
    this.keyword.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return this.service.searchKeyword(term);
      })).subscribe((res: any[]) => {
        this.items = res;
      });
  }

  onSelectItem(item): void {
    if (item.type == "page") {
      this.router.navigate(['/page/', item.slug]);
    }
  }

  reset() {
    this.searchForm.reset();
    this.items = [];
  }

  closeSearch() {
    this.close.emit();
  }

}
