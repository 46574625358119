import {
    House,
    HouseFill,
    HouseDoor,
    HouseDoorFill,
    X,
    Search,
    Person,
    Hash,
    Gear,
    Power,
    ArrowLeftShort,
    PencilSquare,
    Pencil,
    Check2Square,
    Trash,
    Trash2,
    PersonFill,
    At,
    LockFill,
    ChevronRight,
    ChevronLeft,
    ArrowLeft,
    ArrowRight,
    ArrowsMove,
    Calendar4,
    GeoAlt,
    GeoAltFill,
    Map,
    QuestionCircle,
    ChevronUp,
    ChevronDown,
    List,
    Telephone,
    TelephoneFill,
    CaretRightFill,
    CaretDownFill,
    Book,
    Calendar3,
    EnvelopeFill,
    ChevronDoubleRight,
    Plus,
    Dash,
    PersonCircle,
    JournalText,
    Building,
    UiChecksGrid,
    ClockHistory,
    Alarm,
    KeyFill,
    CheckCircle,
    CheckCircleFill,
    ExclamationCircle,
    Upload,
    FileEarmarkText,
    FileEarmarkTextFill,
    Forward,
    XCircle,
    Eye,
    CaretRight,
    CaretDown,
    ArrowUp,
    ArrowDown,
    PeopleFill,
    People,
    Funnel,
    FunnelFill,
    Check2Circle,
    Printer,
    Download
} from 'ngx-bootstrap-icons';

export const icons = {
    House,
    HouseFill,
    HouseDoor,
    HouseDoorFill,
    X,
    Search,
    Person,
    Hash,
    Gear,
    Power,
    ArrowLeftShort,
    PencilSquare,
    Pencil,
    Check2Square,
    Trash,
    Trash2,
    PersonFill,
    At,
    LockFill,
    ChevronRight,
    ChevronLeft,
    ArrowLeft,
    ArrowRight,
    ArrowsMove,
    Calendar4,
    GeoAlt,
    GeoAltFill,
    Map,
    QuestionCircle,
    ChevronUp,
    ChevronDown,
    List,
    Telephone,
    TelephoneFill,
    CaretRightFill,
    CaretDownFill,
    Book,
    Calendar3,
    EnvelopeFill,
    ChevronDoubleRight,
    Plus,
    Dash,
    PersonCircle,
    JournalText,
    Building,
    UiChecksGrid,
    ClockHistory,
    Alarm,
    KeyFill,
    CheckCircle,
    CheckCircleFill,
    ExclamationCircle,
    Upload,
    FileEarmarkText,
    FileEarmarkTextFill,
    Forward,
    XCircle,
    Eye,
    CaretRight,
    CaretDown,
    ArrowUp,
    ArrowDown,
    PeopleFill,
    People,
    Funnel,
    FunnelFill,
    Check2Circle,
    Printer,
    Download
};