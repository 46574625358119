import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class EventService extends DataService {
  constructor(httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/events', httpClient, toastrService);
  }
}