import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MartInfoService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/mart-infos', httpClient, toastrService);
  }

  getLogged() {
    return this.httpClient.get(this.baseUrl + 'api/mart-infos/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }
  slecApprove(resource) {
    return this.httpClient.post(this.baseUrl + 'api/mart-infos/slec-approve', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllMart() {
    return this.httpClient.get(this.baseUrl + 'api/mart-infos/all')
      .pipe(catchError(this.handleError.bind(this)))
  }
  status(resource) {
    return this.httpClient.post(this.baseUrl + 'api/mart-infos/status', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

}