import { Injectable, Inject } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DisbursementNodalService extends DataService {
  constructor(
    httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    toastrService: ToastrService
  ) {
    super(baseUrl + 'api/disbursement-nodal', httpClient, toastrService);
  }

  getView(id?: number, schemeId?: number) {
    let filter = {
      id: id,
      schemeId: schemeId,
    };

    return this.httpClient
      .get(
        this.baseUrl +
          'api/disbursement-nodal/view' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getLogged() {
    return this.httpClient
      .get(this.baseUrl + 'api/disbursement-nodal/logged')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllNodalAppForms() {
    return this.httpClient
      .get(this.baseUrl + 'api/disbursement-nodal/all')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllStartups() {
    return this.httpClient
      .get(this.baseUrl + 'api/disbursement-nodal/startup-schemes')
      .pipe(catchError(this.handleError.bind(this)));
  }
  status(resource) {
    return this.httpClient
      .post(this.baseUrl + 'api/disbursement-nodal/status', resource)
      .pipe(catchError(this.handleError.bind(this)));
  }

  getByIcAppFormIdList(appFormId?: number) {
    let filter = {
      appFormId: appFormId
    }
    return this.httpClient.get(this.baseUrl + 'api/disbursement-nodal/get-by-icappform-id-list' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
  getNodalAporoveAmountSum() {
    return this.httpClient.get(this.baseUrl + 'api/disbursement-nodal/get-nodal-approv-amount-sum')
      .pipe(catchError(this.handleError.bind(this)))
  }
}
