import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NodalAppFormService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/nodal-app-forms', httpClient, toastrService);
  }

  getView(id?: number, schemeId?: number) {
    let filter = {
      id: id,
      schemeId: schemeId
    }

    return this.httpClient.get(this.baseUrl + 'api/nodal-app-forms/view' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getLogged() {
    return this.httpClient.get(this.baseUrl + 'api/nodal-app-forms/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalAppForms() {
    return this.httpClient.get(this.baseUrl + 'api/nodal-app-forms/all')
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirm(resource) {
    return this.httpClient.post(this.baseUrl + 'api/nodal-app-forms/confirm', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  approve(resource) {
    return this.httpClient.post(this.baseUrl + 'api/nodal-app-forms/approve', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  forward(resource) {
    return this.httpClient.post(this.baseUrl + 'api/nodal-app-forms/forward', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllStartups() {
    return this.httpClient.get(this.baseUrl + 'api/nodal-app-forms/startup-schemes')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getDisNodalAppFormList(filter) {
    return this.httpClient.get(this.baseUrl +'api/nodal-app-forms/disbursement-nodal-app-form-list/' +'?' +this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)));
  }
  getStartups() {
    return this.httpClient.get(this.baseUrl + 'api/nodal-app-forms/get-startup-name')
      .pipe(catchError(this.handleError.bind(this)))
  }
}