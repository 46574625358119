import { IOtherInstOrgAcceProg } from './../../../../_models/OtherInstOrgAcceProg/IOtherInstOrgAcceProg';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-inst-org-acce-prog-view',
  templateUrl: './other-inst-org-acce-prog-view.component.html',
  styleUrls: ['./other-inst-org-acce-prog-view.component.css']
})
export class OtherInstOrgAcceProgViewComponent implements OnInit {
  @Input() otherInstOrgAcceProg: IOtherInstOrgAcceProg;

  constructor() { }

  ngOnInit(): void {
  }


}
