import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DashboardService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/dashboards', httpClient, toastrService);
  }

  getAllDashboardCount(userId?: string) {
    let filter = {
      userId: userId
    }
    return this.httpClient.get(this.baseUrl + 'api/dashboards/counts' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalDashboardCount(userId?: string) {
    let filter = {
      userId: userId
    }
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodal-counts' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllHomeDashboardCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/home-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllMentorCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/mentor-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodal-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getUserDashboardCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/users-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }
}