import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionChangePasswordService {
  
  constructor() {
  }

  encrypt(text: string): string {
    const key = CryptoJS.enc.Utf8.parse("5TGB&YHN7UJM(IK<5TGB&YHN7UJM(IK<");
    const iv = CryptoJS.enc.Utf8.parse("!QAZ2WSX#EDC4RFV");

    const encrypted = CryptoJS.AES.encrypt(
      text,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7 
      }
    );

    return encrypted.toString();
  }
}