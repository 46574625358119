import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IcIncubatorProfileCComponent } from './ic-incubator-profile-c/ic-incubator-profile-c.component';

export function tokenGetter() {
  return (typeof window !== "undefined") ? localStorage.getItem("token") : null;
}

@NgModule({
  declarations: [
    AppComponent,
    IcIncubatorProfileCComponent,
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:5010", "localhost:5011","localhost:44348", "startupdemo.orpgujarat.com", "startup.gujarat.gov.in","demostup.orpgujarat.com", "http://startuptest.orpgujarat.com/", "startupgujarat.in"],
        // whitelistedDomains: ["startupgujarat.in"],
      }
    }),
    SharedModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
