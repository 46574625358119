import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppFormService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/app-forms', httpClient, toastrService);
  }

  getView(id?: number, schemeId?: number) {
    let filter = {
      id: id,
      schemeId: schemeId
    }

    return this.httpClient.get(this.baseUrl + 'api/app-forms/view' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getLogged() {
    return this.httpClient.get(this.baseUrl + 'api/app-forms/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirm(id: number) {
    return this.httpClient.post(this.baseUrl + 'api/app-forms/Confirm' + '/' + id, {})
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappform() {
    return this.httpClient.get(this.baseUrl + 'api/app-forms/all')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAlldataappform() {
    return this.httpClient.get(this.baseUrl + 'api/app-forms/all-data')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getLoggedListAppForm(userId) {
    let filter = {
      userId: userId,
    }
    return this.httpClient.get(this.baseUrl + 'api/app-forms/logged-list' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
}