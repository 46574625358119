import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../api/services/auth.service";

@Injectable()
export class InvesterAuthGuard implements CanActivate {
  constructor(private route: Router, private authService: AuthService) { }

  canActivate() {
    let user = this.authService.currentUser;
    let roles = this.authService.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if (user) {
      var user_role = typeof roles == 'object' ? roles.filter(x => x == "invester") : roles;
      if (user_role && user_role == "invester") {
        return true;
      }
    }
    //this.route.navigate(['/no-access']);
    return true;
  }
}