import { Injectable, Inject } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class StartuptoMentorMeetingService extends DataService {
  constructor(
    httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    toastrService: ToastrService
  ) {
    super(baseUrl + 'api/startupto-mentor-meetings', httpClient, toastrService);
  }

  status(resource) {
    return this.httpClient
      .post(this.baseUrl + 'api/startupto-mentor-meetings/status', resource)
      .pipe(catchError(this.handleError.bind(this)));
  }

  feedback(resource) {
    return this.httpClient
      .post(this.baseUrl + 'api/startupto-mentor-meetings/feedback', resource)
      .pipe(catchError(this.handleError.bind(this)));
  }

  mentorStatistics() {
    return this.httpClient
      .get(this.baseUrl + 'api/startupto-mentor-meetings/mentor-statistics')
      .pipe(catchError(this.handleError.bind(this)));
  }

  companyStatistics() {
    return this.httpClient
      .get(this.baseUrl + 'api/startupto-mentor-meetings/company-statistics')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getStartupsName() {
    return this.httpClient
      .get(this.baseUrl + 'api/startupto-mentor-meetings/get-startup-name')
      .pipe(catchError(this.handleError.bind(this)));
  }
  getMeetinsByCompnyId(compnyid?: string) {
    let filter = {
      compnyid: compnyid,
    };
    return this.httpClient
      .get(
        this.baseUrl +
          'api/startupto-mentor-meetings/get-meetins-by-compnyid' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
}
