import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GalleryItemsService extends DataService {

  constructor(httpClient: HttpClient, private authService: AuthService, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/gallery-items', httpClient, toastrService);
  }

  getAllGalleries() {
    return this.httpClient.get(this.baseUrl + 'api/gallery-items/all')
      .pipe(catchError(this.handleError))
  }

  postFile(files: FileList, galleryId: number) {
    const formData: FormData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files', files.item(index), files.item(index).name);
    }
    let token = this.authService.getToken;
    return this.httpClient
      .post(this.baseUrl + 'api/gallery-items?galleryId=' + galleryId, formData, { reportProgress: true, observe: 'events' })
      .pipe(catchError(this.handleError))
  }

}