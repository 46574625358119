import { IScheme } from './../../../_models/cms/IScheme';
import { CommonService } from './../../../api/services/common.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assistance-list',
  templateUrl: './assistance-list.component.html',
  styleUrls: ['./assistance-list.component.css']
})
export class AssistanceListComponent implements OnInit {
  @Input() deptId?: number;
  @Input() userType?: number;

  schemes: IScheme[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: CommonService
  ) { }

  ngOnInit(): void {
    this.loadAssistance();
  }

  loadAssistance() {
    this.service.getAllSchemes(this.deptId, this.userType).subscribe((response: IScheme[]) => {
      if (response) {
        this.schemes = response;
      }
    })
  }
}
