import { IOrganizingAccelerationProgram } from './../../../../_models/organizing-acceleration-program/IOrganizingAccelerationProgram';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizing-acceleration-program-view',
  templateUrl: './organizing-acceleration-program-view.component.html',
  styleUrls: ['./organizing-acceleration-program-view.component.css']
})
export class OrganizingAccelerationProgramViewComponent implements OnInit {
  @Input() organizingAccelerationProgram: IOrganizingAccelerationProgram;

  constructor() { }

  ngOnInit(): void {
  }

}
