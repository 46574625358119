import { Component, Input, OnInit } from '@angular/core';
import { SeedSupportDocService } from './../../../../api/services/seed-support-doc.service';
import { MediaFileService } from 'src/app/api/services/media-file.service';

@Component({
  selector: 'app-scheme-docs-view',
  templateUrl: './scheme-docs-view.component.html',
  styleUrls: ['./scheme-docs-view.component.css']
})
export class SchemeDocsViewComponent implements OnInit {
  @Input() appFormId;

  seedSupportDocs: any[];
  constructor(
    private service: SeedSupportDocService,
    private mediaFileService: MediaFileService
  ) { }

  ngOnInit(): void {
    this.getSchemeDocs();
  }

  getSchemeDocs() {
    this.service.getAllSchemeDocs(this.appFormId).subscribe((response) => {
      if (response) {
        this.seedSupportDocs = response;
      }
    })
  }
  downloaddoc(id) {
    debugger
    if (id) {
      this.mediaFileService.checkfileexist(id).subscribe((response: any) => {
        if (response) {
          this.mediaFileService.getfiledown(id).subscribe((data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = downloadURL;
            link.click();
          });
        }
      });
    }
  }

}
