import { IGallery } from './../../../_models/cms/IGallery';
import { GalleryService } from './../../../api/services/gallery.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html'
})
export class GalleryComponent implements OnInit {

  slideOneConfig = { "lazyLoad": 'ondemand', "slidesToShow": 1, "asNavFor": '.slider-nav-vs', "slidesToScroll": 1, "arrows": false, "dots": false, "autoplay": true, "autoplaySpeed": 3000 };
  slideTwoConfig = {
    "lazyLoad": 'ondemand', "slidesToShow": 3, "asNavFor": '.slider-for-vs', "slidesToScroll": 1, "arrows": true, "dots": false, "autoplay": true, "autoplaySpeed": 3000,
    "responsive": [
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 3,
          "slidesToScroll": 3,
          "infinite": true,
          "dots": true
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2
        }
      },
      {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1
        }
      }]
  };
  slideConfig = { "lazyLoad": 'ondemand', "slidesToShow": 1, "slidesToScroll": 1, "arrows": true, "dots": false, "autoplay": true, "autoplaySpeed": 2000 };

  galleries: IGallery[] = [];
  gallery: IGallery;
  url: string;

  constructor(
    private service: GalleryService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getGallery();
  }

  getGallery() {
    this.service.getFeatured().subscribe((response: IGallery[]) => {
      if (response) {
        this.galleries = response;
      }
    })
  }

  youtube_parser(url: string) {
    var stepTwo = url.split('/');
    var videoId = stepTwo[stepTwo.length - 1];
    return videoId;
  }

  open(content, item) {
    this.gallery = item;
    this.modalService.open(content, { size: 'xl' });
  }

}
