import { CompanyService } from './../../../../api/services/company.service';
import { ICompany } from './../../../../_models/company/ICompany';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-nodal-view',
  templateUrl: './company-nodal-view.component.html',
  styleUrls: ['./company-nodal-view.component.css']
})
export class CompanyNodalViewComponent implements OnInit {

  @Input() userId;

  company: ICompany;

  constructor(
    private service: CompanyService
  ) { }

  ngOnInit(): void {
    this.getCompanyData();
  }

  getCompanyData() {
    this.service.get(this.userId).subscribe((response: ICompany) => {
      if (response) {
        this.company = response;
      }
    })
  }

  getFounder(v: ICompany) {
    var companyTeam = v.companyTeams.filter(x => x.designationId == 1).length > 0 ? v.companyTeams.filter(x => x.designationId == 1)[0] : null;
    return companyTeam;
  }

}
