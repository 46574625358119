import { IProfile, IEmailLogin, IConfirmEmailLogin } from './../../_models/account/user';
import { IForgotPassword, IResetPassword, IConfirmEmail, ILogin, IRegister, IChangePassword } from '../../_models/account/user';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Router } from "@angular/router";
import { DataService } from "./data.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthService extends DataService {
  userToken: any;
  constructor(
    httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    @Inject('BASE_URL') private _baseUrl: string, toastrService: ToastrService) {
    super(_baseUrl + 'api/account', httpClient, toastrService);
  }

  login(login: ILogin) {
    return this.httpClient.post(this._baseUrl + 'api/account/login', login)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirmEmail(confirmEmail: IConfirmEmail) {
    return this.httpClient.post(this._baseUrl + 'api/account/confirm-email', confirmEmail)
      .pipe(catchError(this.handleError.bind(this)))
  }

  emailLogin(emailLogin: IEmailLogin) {
    return this.httpClient.post(this._baseUrl + 'api/account/email-login', emailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirmEmailLogin(confirmEmailLogin: IConfirmEmailLogin) {
    return this.httpClient.post(this._baseUrl + 'api/account/confirm-email-login', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }

  editProfile(profile: IProfile) {
    return this.httpClient.post(this._baseUrl + 'api/account/update-profile', profile)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getProfile() {
    return this.httpClient.get(this._baseUrl + 'api/account/profile')
      .pipe(catchError(this.handleError.bind(this)))
  }

  forgotPassword(forgotPassword: IForgotPassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/forgot-password', forgotPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  resetPassword(resetPassword: IResetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/reset-password', resetPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }


  register(register: IRegister) {
    return this.httpClient.post(this._baseUrl + 'api/account/register', register)
      .pipe(catchError(this.handleError.bind(this)))
  }

  changePassword(changePassword: IChangePassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/change-password', changePassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  changeFirstPassword(changePassword: IChangePassword) {
    return this.httpClient.post(this._baseUrl + 'api/account/change-first-password', changePassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  startupIndiaUserRegister(emailLogin: IEmailLogin) {
    return this.httpClient.post(this._baseUrl + 'api/account/startupindia-user-register', emailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/account/login']);
  }

  isLoggedIn() {
    return !this.jwtHelper.isTokenExpired();
  }

  get currentUser() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token);
  }

  get getToken() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;
    return token;
  }

  get currentUserId() {
    let token = localStorage.getItem('token');
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  isInRole(role: string) {
    let roles = this.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    if (roles) {
      if (typeof roles == 'object' && roles.filter(x => x == role) == role) {
        return true;
      }
      else if (typeof roles == 'string' && roles == role) {
        return true;
      }
    }
    return false;
  }

  redirectToHome() {
    if (this.isLoggedIn() && this.isInRole("nodal")) {
      this.router.navigate(['/nodal']);
    }
    else if (this.isLoggedIn() && this.isInRole("otherinst")) {
      this.router.navigate(['/other-inst']);
    }
    else if (this.isLoggedIn() && this.isInRole("mentor")) {
      this.router.navigate(['/mentor']);
    }
    else if (this.isLoggedIn() && this.isInRole("user")) {
      this.router.navigate(['/company']);
    }
    else if (this.isLoggedIn() && this.isInRole("ic")) {
      this.router.navigate(['/ic']);
    }
    else if (this.isLoggedIn() && this.isInRole("scrutiny")) {
      this.router.navigate(['/scrutiny']);
    }
    else if (this.isLoggedIn() && this.isInRole("slec")) {
      this.router.navigate(['/slec']);
    }
    else if (this.isLoggedIn() && this.isInRole("indcomm")) {
      this.router.navigate(['/indcomm']);
    }
    else if (this.isLoggedIn() && this.isInRole("invester")) {
      this.router.navigate(['/invester']);
    }
  }

  redirectBackURL(urlname) {
    var userPath = "";
    if (this.isLoggedIn() && this.isInRole("nodal")) {
      userPath = 'nodal';
    }
    else if (this.isLoggedIn() && this.isInRole("otherinst")) {
      userPath = 'otherinst';
    }
    else if (this.isLoggedIn() && this.isInRole("mentor")) {
      userPath = 'mentor';
    }
    else if (this.isLoggedIn() && this.isInRole("user")) {
      userPath = 'company';
    }
    else if (this.isLoggedIn() && this.isInRole("ic")) {
      userPath = 'ic';
    }
    else if (this.isLoggedIn() && this.isInRole("scrutiny")) {
      userPath = 'scrutiny';
    }
    else if (this.isLoggedIn() && this.isInRole("slec")) {
      userPath = 'slec';
    }
    else if (this.isLoggedIn() && this.isInRole("indcomm")) {
      userPath = 'indcomm';
    }
    else if (this.isLoggedIn() && this.isInRole("invester")) {
      userPath = 'invester';
    }
    return '/' + userPath + '/' + urlname;
  }
}
