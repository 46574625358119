import { MeetingListComponent } from './../../../../company/components/meeting/meeting-list/meeting-list.component';
import { IIcAppForm } from 'src/app/_models/ic/IIcAppForm';
import { IMentoringStartup } from './../../../../_models/mentoring-startup/IMentoringStartup';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentoring-startup-view',
  templateUrl: './mentoring-startup-view.component.html',
  styleUrls: ['./mentoring-startup-view.component.css'],
})
export class MentoringStartupViewComponent implements OnInit {
  @Input() mentoringStartup: IMentoringStartup;
  private total = 0;
  private value;
  private value1;
  public totolHours;
  constructor() {}

  ngOnInit(): void {
    this.findsum(this.mentoringStartup.mentoringStartupApprovals);
  }
  findsum(data) {
    this.value = data;
    const secondsToHm = s => ({
      hours: ((s - s % 3600) / 3600) % 60, 
      minutes: ((s - s % 60) / 60) % 60, 
    })
    for (let j = 0; j < data.length; j++) {
      this.value1 = this.value[j].appForm.user.company.meetings;
      for (var i = 0; i < this.value[j].appForm.user.company.meetings.length; i++) {
        const aSlice = this.value1[i].feedbackMeetingTime.split(':');
        const aSeconds = (+aSlice[0]) * 60 * 60 + (+aSlice[1]) * 60;
        this.total += aSeconds
      }
      // console.log(this.total);
      // console.log(secondsToHm(this.total).hours,secondsToHm(this.total).minutes);
      this.totolHours = secondsToHm(this.total).hours+':'+secondsToHm(this.total).minutes;
      // console.log(secondsToHm(this.total).minutes);
    }
  }
}
