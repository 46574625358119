import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GrievanceService extends DataService {
  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/grievances', httpClient, toastrService);
  }
  
  getGrievanceByNo(ticketNo: string) {
    return this.httpClient.get(this.baseUrl + 'api/grievances/ticket-no/' + ticketNo)
      .pipe(catchError(this.handleError.bind(this)))
  }

  
  replyGrievance(id, resource) {
    return this.httpClient.put(this.baseUrl + 'api/grievances/reply' + '/' + id, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
}