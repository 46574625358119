import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CommonService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/common', httpClient, toastrService);
  }

  // 
  getAllStates() {
    return this.httpClient.get(this.baseUrl + 'api/common/states')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllDistricts(stateId) {
    return this.httpClient.get(this.baseUrl + 'api/common/districts?stateId=' + stateId)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllTalukas(districtId) {
    return this.httpClient.get(this.baseUrl + 'api/common/talukas?districtId=' + districtId)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllRegTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/reg-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllUidTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/uid-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllGenders() {
    return this.httpClient.get(this.baseUrl + 'api/common/genders')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllDesignations() {
    return this.httpClient.get(this.baseUrl + 'api/common/designations')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllSectors() {
    return this.httpClient.get(this.baseUrl + 'api/common/sectors')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllServiceArea() {
    return this.httpClient.get(this.baseUrl + 'api/common/service-areas')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllDepartments() {
    return this.httpClient.get(this.baseUrl + 'api/common/departments')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllSchemes(deptId?: number, userType?: number) {
    let filter = {
      deptId: deptId,
      userType: userType
    }
    return this.httpClient.get(this.baseUrl + 'api/common/schemes' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllPolicyCategories() {
    return this.httpClient.get(this.baseUrl + 'api/common/policy-categories')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllStartupFacilities() {
    return this.httpClient.get(this.baseUrl + 'api/common/startup-facilities')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllDocTypes(userType: number, schemeId?: number) {
    let filter = {
      userType: userType,
      schemeId: schemeId
    }

    return this.httpClient.get(this.baseUrl + 'api/common/doc-types' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllCountries() {
    return this.httpClient.get(this.baseUrl + 'api/common/countries')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllGrievanceTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/grievance-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllQueryTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/query-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllFieldAreas() {
    return this.httpClient.get(this.baseUrl + 'api/common/field-areas')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllEventTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/event-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllEventSubType(eventTypeId?: string) {
    let filter = {
      eventTypeId: eventTypeId
    }
    return this.httpClient.get(this.baseUrl + 'api/common/event-sub-types' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalCoreTeams() {
    return this.httpClient.get(this.baseUrl + 'api/common/nodal-core-teams')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllDashboardCount(userId?: string) {
    let filter = {
      userId: userId
    }
    return this.httpClient.get(this.baseUrl + 'api/dashboards/counts' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalDashboardCount(userId?: string) {
    let filter = {
      userId: userId
    }
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodal-counts' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllOtherInstDashboardCount(userId?: string) {
    let filter = {
      userId: userId
    }
    return this.httpClient.get(this.baseUrl + 'api/dashboards/other-inst-counts' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllHomeDashboardCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/home-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllOrganizingEvents() {
    return this.httpClient.get(this.baseUrl + 'api/common/organizing-events')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllOperationTypes() {
    return this.httpClient.get(this.baseUrl + 'api/common/operation-types')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllTagGroups() {
    return this.httpClient.get(this.baseUrl + 'api/common/tag-groups')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllRecommendationTypes(schemeId?: number) {
    let filter = {
      schemeId: schemeId
    }

    return this.httpClient.get(this.baseUrl + 'api/common/recommendation-types' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllAccelerationPrograms() {
    return this.httpClient.get(this.baseUrl + 'api/common/acceleration-programs')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getOrganizingAccelerationPrograms() {
    return this.httpClient.get(this.baseUrl + 'api/common/organizing-acceleration-programs')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllCurrentStages() {
    return this.httpClient.get(this.baseUrl + 'api/common/current-stages')
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAllMentorCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/mentor-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllInvesterCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/invester-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllMartCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/mart-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodal-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }

  getUserDashboardCount() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/users-dashboard-counts')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAppObject(appObjectTypeId?: number) {
    let filter = {
      appObjectTypeId: appObjectTypeId
    }
    return this.httpClient.get(this.baseUrl + 'api/common/app-objects' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAppObjectType() {
    return this.httpClient.get(this.baseUrl + 'api/common/app-object-types')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getVisitorDashboardByDistrictCount(filter: any) {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/no-of-count-visitor'+ '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllStastatics() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/generate-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllAppformStastatics() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/appform-scheme-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllNodalAppformStastatics() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodal-scheme-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllcafStastatics() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/caf-reg-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappformapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/app-form-approval-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappformnodalapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/appform-scheme-count-by-nodal')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappformicapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/appform-scheme-count-by-ic')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappformindcomapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/appform-scheme-count-by-indcom')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappformscruapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/appform-scheme-count-by-scrutiny')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllappformslecapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/appform-scheme-count-by-slec')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllnodalappformapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodalapp-form-approval-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllnodalappformicapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodalapp-form-approval-ic-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllnodalappformindcomapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodalapp-form-approval-ind-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAllnodalappformslecapproval() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/nodalapp-form-approval-slec-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getAlldetailprofileStastatics() {
    return this.httpClient.get(this.baseUrl + 'api/dashboards/detail-profile-count')
      .pipe(catchError(this.handleError.bind(this)))
  }
}