import { ISkillDevelopment } from './../../../../_models/skill-development/ISkillDevelopment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-development-view',
  templateUrl: './skill-development-view.component.html',
  styleUrls: ['./skill-development-view.component.css']
})
export class SkillDevelopmentViewComponent implements OnInit {
  @Input() skillDevelopment: ISkillDevelopment;

  constructor() { }

  ngOnInit(): void {
  }

}
