import { IProfile } from './../../../_models/account/user';
import { Observable } from 'rxjs';
import { ProfileService } from './../../../api/services/profile.service';
import { AuthService } from '../../../api/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.css']
})
export class UserBarComponent implements OnInit {
  profile$: Observable<IProfile>;
  email: string = '';
  _authService;
  constructor(private authService: AuthService,
    private profileService: ProfileService) {
    this._authService = authService;
    this.email = this.authService.currentUser.sub;
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.getProfile();
    }

    this.profile$ = this.profileService.profile$;
  }

  getProfile() {
    this.authService.getProfile().subscribe((response: IProfile) => {
      if (response) {
        this.profileService.changeProfile(response);
      }
    });
  }

}
