import { IAppForm } from './../../../../_models/company/IAppForm';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheme-view',
  templateUrl: './scheme-view.component.html',
  styleUrls: ['./scheme-view.component.css']
})
export class SchemeViewComponent implements OnInit {
  @Input() appForm: IAppForm;
  constructor() { }

  ngOnInit(): void {
  }

}
