import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class IndCommAppFormService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/ind-com-app-forms', httpClient, toastrService);
  }

  getView(id?: number, schemeId?: number) {
    let filter = {
      id: id,
      schemeId: schemeId
    }

    return this.httpClient.get(this.baseUrl + 'api/ind-com-app-forms/view' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodalAppForms() {
    return this.httpClient.get(this.baseUrl + 'api/ind-com-app-forms/all')
      .pipe(catchError(this.handleError.bind(this)))
  }

  approve(resource) {
    return this.httpClient.post(this.baseUrl + 'api/ind-com-app-forms/approve', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  getDisStartupList(filter) {
    return this.httpClient
      .get(this.baseUrl +'api/ind-com-app-forms/disbursement-startup-list/' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
}