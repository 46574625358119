
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGrievance } from 'src/app/_models/grievance/IGrievance';
import { GrievanceService } from 'src/app/api/services/grievance.service';

@Component({
  selector: 'app-grievance-form-remark',
  templateUrl: './grievance-form-remark.component.html',
  styleUrls: ['./grievance-form-remark.component.css']
})
export class GrievanceFormComponent implements OnInit {

  loading: boolean;
  title: string = 'Reply Grievance';
  @Input() grievance: IGrievance;
  @Output() close: EventEmitter<any> = new EventEmitter();
  grievanceForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private service: GrievanceService) {
  }

  ngOnInit() {
    this.createGrievanceForm();

  }

  ngOnDestroy() {
  }

  createGrievanceForm() {
    this.grievanceForm = this.fb.group({
      id: [this.grievance.id, Validators.required],
      remark: [this.grievance.remark, Validators.required],
      status: [true]
    });
  }

  save() {
    if (this.grievanceForm.valid) {
      this.loading = true;
      const replyGrievance = {
        id: this.grievance.id,
        remark: this.grievanceForm.get('remark').value,
        status: this.grievanceForm.get('status').value,
      }
      this.service.replyGrievance(this.grievance.id, replyGrievance).subscribe(() => {
        this.toastrService.success('Grievance has been updated successfully.');
        this.close.emit();
      },
        (error) => console.log('HTTP Error', error),
        () => this.loading = false);
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
