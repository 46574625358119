import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {
  logos: any[] = [
    { img: '/assets/img/logo/logo_01.png', alt: 'IC', link: 'http://www.imd-gujarat.gov.in/' },
    { img: '/assets/img/logo/logo_02.png', alt: 'Year of Celebrating The Mahatma', link: 'https://dst.gujarat.gov.in/' },
    { img: '/assets/img/logo/logo_03.png', alt: 'GEM', link: 'https://gem.gov.in/' },
    { img: '/assets/img/logo/logo_04.png', alt: 'IFP Portal', link: 'https://ifp.gujarat.gov.in/' },
    { img: '/assets/img/logo/logo_05.png', alt: 'Make in india', link: 'https://www.makeinindia.com/home' },
    { img: '/assets/img/logo/logo_06.png', alt: 'Statue of Unity', link: 'https://www.soutickets.in/#/dashboard' },
    { img: '/assets/img/logo/logo_07.png', alt: 'Startup India', link: 'https://www.startupindia.gov.in/' },
    { img: '/assets/img/logo/logo_08.png', alt: 'Startup India', link: 'https://btm.gujarat.gov.in/' },
    { img: '/assets/img/logo/g20_logo.png', alt: 'g-20', link: 'https://www.g20.org/en/' }
  ];

  slideConfig = {
    "lazyLoad": 'ondemand',
    "slidesToShow": 5,
    "infinite": true,
    "slidesToScroll": 1,
    "arrows": false,
    "dots": false,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "responsive": [
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 6,
          "slidesToScroll": 3,
          "infinite": true,
          "dots": true
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 4,
          "slidesToScroll": 2
        }
      },
      {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
