import { IOtherInstOrgAcceProgFinal } from './../../../../_models/OtherInstOrgAcceProgFinal/IOtherInstOrgAcceProgFinal';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-inst-org-acce-prog-final-view',
  templateUrl: './other-inst-org-acce-prog-final-view.component.html',
  styleUrls: ['./other-inst-org-acce-prog-final-view.component.css']
})
export class OtherInstOrgAcceProgFinalViewComponent implements OnInit {
  @Input() otherInstOrgAcceProgFinal: IOtherInstOrgAcceProgFinal;

  constructor() { }

  ngOnInit(): void {
  }

}
