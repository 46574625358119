import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SsipInstituteService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/ssip-institutes', httpClient, toastrService);
  }

}