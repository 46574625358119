import { Injectable, Inject } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable()
export class IcAppFormService extends DataService {
  constructor(
    httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    toastrService: ToastrService
  ) {
    super(baseUrl + 'api/ic-app-forms', httpClient, toastrService);
  }

  export(
    nodalAppFormId?: number,
    nodalSchemeAppFormId?: number,
    status?: number
  ) {
    let filter = {
      nodalAppFormId: nodalAppFormId,
      nodalSchemeAppFormId: nodalSchemeAppFormId,
      status: status,
    };

    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/export' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getView(id?: number, schemeId?: number) {
    let filter = {
      id: id,
      schemeId: schemeId,
    };

    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/view' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  getSecureUrl(): Observable<any> {
    return this.httpClient.get(
      this.baseUrl +
        'generate-url')
  }

  getAllNodalAppForms() {
    return this.httpClient
      .get(this.baseUrl + 'api/ic-app-forms/all')
      .pipe(catchError(this.handleError.bind(this)));
  }

  confirm(resource) {
    return this.httpClient
      .post(this.baseUrl + 'api/ic-app-forms/confirm', resource)
      .pipe(catchError(this.handleError.bind(this)));
  }

  approve(resource) {
    return this.httpClient
      .post(this.baseUrl + 'api/ic-app-forms/approve', resource)
      .pipe(catchError(this.handleError.bind(this)));
  }
  getDisStartupList(filter) {
    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/disbursement-startup-list/' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  getDisStartupListIndCom(filter) {
    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/disbursement-startup-list-indcom/' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  getDisNodalList(filter) {
    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/disbursement-nodal-list/' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  getDisNodalListIndCom(filter) {
    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/disbursement-nodal-list-indcom/' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  
  getDisStartupReportList(filter) {
    return this.httpClient
      .get(
        this.baseUrl +
          'api/ic-app-forms/disbursement-startup-report-list/' +
          '?' +
          this.toQueryString(filter)
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  getLoggedListAppForm(userId) {
    let filter = {
      userId: userId,
    }
    return this.httpClient.get(this.baseUrl + 'api/ic-app-forms/logged-list' + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }
}
