import { IOrganizingEventFinal } from './../../../../_models/organizing-event-final/IOrganizingEventFinal';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizing-event-final-view',
  templateUrl: './organizing-event-final-view.component.html',
  styleUrls: ['./organizing-event-final-view.component.css']
})
export class OrganizingEventFinalViewComponent implements OnInit {
  @Input() organizingEventFinal: IOrganizingEventFinal;
  
  constructor() { }

  ngOnInit(): void {
  }

}
