import { IProfile } from './../../_models/Account/user';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private profileSource = new BehaviorSubject<IProfile>(null);
  profile$ = this.profileSource.asObservable();

  constructor() {
  }

  changeProfile(profile: IProfile) {
    this.profileSource.next(profile);
  }

  getProfile() {
    this.profileSource.getValue();
  }

}
