import { IMediaFile } from '../../_models/cms/IMediaFile';
import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class OtherInstSchemeDocService extends DataService {
    apiUrl: string;
    constructor(httpClient: HttpClient, private authService: AuthService, @Inject('BASE_URL') baseUrl: string, toastrService: ToastrService) {
        super(baseUrl + 'api/other-inst-scheme-docs', httpClient, toastrService);
        this.apiUrl = baseUrl + 'api/other-inst-scheme-docs';
    }

    private selectedMediaFile = new BehaviorSubject<IMediaFile | null>(null);
    selectedMediaFileChanges$ = this.selectedMediaFile.asObservable();

    changeSelectedMediaFile(selectedMediaFile: IMediaFile | null): void {
        this.selectedMediaFile.next(selectedMediaFile);
    }

    authPostFile(otherInstAppFormId: any, docTypeId: any, files: FileList) {
        const formData: FormData = new FormData();
        formData.append('otherInstAppFormId', otherInstAppFormId);
        formData.append('docTypeId', docTypeId);
        for (let index = 0; index < files.length; index++) {
            formData.append('file', files.item(index), files.item(index).name);
        }

        let token = this.authService.getToken;
        return this.httpClient
            .post(this.apiUrl + '/single', formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
            .pipe(catchError(this.handleError.bind(this)))
    }

}
