import { AuthService } from './../../../api/services/auth.service';
import { MenuService } from './../../../api/services/menu.service';
import { IMenu, IMenuContent } from './../../../_models/cms/IMenu';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @Input() class: string = "bg-hover-white bg-fixed-white navbar-fixed-dark navbar-hover-dark bg-transparent navbar-dark";
  @Input() header: string = "header-absolute";
  @Input() top: string = "";
  @Input() hideMenu: boolean = false;
  @Input() hideUser: boolean = false;
  @Input() hideCall: boolean = false;
  @ViewChild('stickyMenu') menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;
  isCollapsed: boolean = true;
  isSearch: boolean = false;

  primaryMenuContents: IMenuContent[];

  _authService;
  _translate;
  currentLang;
  constructor(
    public translate: TranslateService,
    private menuService: MenuService,
    private authService: AuthService) {
    this._authService = authService;
    this._translate=  this.translate;
  }

  ngOnInit(): void {
    this.getMenu();
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

   getMenu() {
    if(this.translate.currentLang == 'Gujarati')
    {
    this.menuService.get(2).subscribe((response: IMenu) => {
      if (response) {
        this.primaryMenuContents = response.menuContents.filter(x => x.parentMenuContentId == null)
          .sort(this.GetSortOrder("order"));
      }
    });
  }else
  {
    this.menuService.get(1).subscribe((response: IMenu) => {
      if (response) {
        this.primaryMenuContents = response.menuContents.filter(x => x.parentMenuContentId == null)
          .sort(this.GetSortOrder("order"));
      }
    });
  }
  }

  GetSortOrder(prop) {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

}
