import { IPaged } from './../../../_models/common/IPaged';
import { PolicyService } from './../../../api/services/policy.service';
import { HeaderService } from './../../services/header.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['./policy-list.component.css']
})
export class PolicyListComponent implements OnInit {
  @Input() slug: string = "";
  private readonly PAGE_SIZE = 12;

  queryResult: IPaged = {
    pageNumber: 0,
    items: [],
    totalRows: 0,
    pageSize: this.PAGE_SIZE
  };

  query: any = {
    page: 1,
    pageSize: this.PAGE_SIZE,
    sort: 'updatedAt',
    sortDir: 'Descending',
    slug: '',
    title: ''
  };

  sub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private service: PolicyService
  ) {

  }

  ngOnInit(): void {
    this.sub = combineLatest(this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })).subscribe(ap => {
        this.query.page = isNaN(ap.qparams.page) ? 1 : ap.qparams.page;
        this.query.sort = ap.qparams.sort ? ap.qparams.sort : this.query.sort;
        this.query.sortDir = ap.qparams.sortDir ? ap.qparams.sortDir : this.query.sortDir;
        this.query.slug = ap.qparams.slug;
        this.populatePolicies();
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private populatePolicies() {
    this.query.slug = this.slug;
    this.service.getAll(this.query)
      .subscribe((response: IPaged) => {
        if (response) {
          this.queryResult = response;
        }
      });
  }

}
