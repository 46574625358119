import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class StartupIndiaService extends DataService {

    constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
        super(baseUrl + 'api/common', httpClient, toastrService);
    }

    // 
    getUserDetails(token) {
        debugger
        return this.httpClient.get('https://api.startupindia.gov.in/sih/api/auth/sso/services/get/userDetails', { headers: new HttpHeaders().set('x-auth-token', token) })
            .pipe(catchError(this.handleError.bind(this)))
    }

    getDPIITDetails(token, dpiit) {
        debugger
        // var data = {
        //     "dpiitNumber": "DIPP10964"
        // }
         var data = {
            "dpiitNumber": dpiit
        }

       //var stringFix = "{\"dpiitNumber\": \"DIPP10964\"}"
        
        return this.httpClient.post('https://api.startupindia.gov.in/sih/api/auth/sso/services/get/dpiit', data, { headers: new HttpHeaders().set('x-auth-token', token) })
            .pipe(catchError(this.handleError.bind(this)))
    }
}