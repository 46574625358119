import { IMediaFile } from '../../_models/cms/IMediaFile';
import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MediaFileService extends DataService {
  private _apiUrl: string;
  constructor(httpClient: HttpClient, private authService: AuthService, @Inject('BASE_URL') baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/media-files', httpClient, toastrService);
    this._apiUrl = baseUrl + 'api/media-files';
  }

  private selectedMediaFile = new BehaviorSubject<IMediaFile | null>(null);
  selectedMediaFileChanges$ = this.selectedMediaFile.asObservable();

  changeSelectedMediaFile(selectedMediaFile: IMediaFile | null): void {
    this.selectedMediaFile.next(selectedMediaFile);
  }

  postFile(files: FileList) {
    const formData: FormData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files', files.item(index), files.item(index).name);
    }
    let token = this.authService.getToken;
    return this.httpClient
      .post(this._apiUrl, formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
      .pipe(catchError(this.handleError.bind(this)))
  }

  authPostFile(files: FileList) {
    const formData: FormData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files', files.item(index), files.item(index).name);
    }
    let token = this.authService.getToken;
    return this.httpClient
      .post(this._apiUrl, formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
      .pipe(catchError(this.handleError.bind(this)));
  }


  authPostSingleFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let token = this.authService.getToken;
    return this.httpClient
      .post(this._apiUrl + "/single", formData, { reportProgress: true, observe: 'events', headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) })
      .pipe(catchError(this.handleError.bind(this)));
  }
  checkfileexist(filter: any) {
    return this.httpClient.get(this._apiUrl +  "/media-file-down-exist/" + filter)
      .pipe(catchError(this.handleError.bind(this)))
  }
  getfiledown(filter: any) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get(this._apiUrl + "/media-file-down/"+ filter, httpOptions)
      .pipe(catchError(this.handleError.bind(this)))
  }
  getfiledownload(filter: any) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get(this._apiUrl + "/download-file"+ filter, httpOptions)
      .pipe(catchError(this.handleError.bind(this)))
  }


}
