import { ISocialImpact } from './../../../../_models/social-impact/ISocialImpact';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-impact-view',
  templateUrl: './social-impact-view.component.html',
  styleUrls: ['./social-impact-view.component.css']
})
export class SocialImpactViewComponent implements OnInit {
  @Input() socialImpact: ISocialImpact;

  constructor() { }

  ngOnInit(): void {
  }

}
