import { ICompany } from 'src/app/_models/company/ICompany';
import { IMartInfo } from 'src/app/_models/company/IMartInfo';
import { IInvester } from './../../../../_models/invester/IInvester';
import { Component, Input, OnInit } from '@angular/core';
import { MediaFileService } from 'src/app/api/services/media-file.service';

@Component({
  selector: 'app-mart-info-view',
  templateUrl: './mart-info-view.component.html',
  styleUrls: ['./mart-info-view.component.css']
})
export class MartInfoViewComponent implements OnInit {

  @Input() invester: IMartInfo;
  constructor(
    private mediaFileService: MediaFileService,
  ) { }

  ngOnInit(): void {
  }
  downloaddoc(id) {
    debugger
    if (id) {
      this.mediaFileService.checkfileexist(id).subscribe((response: any) => {
        if (response) {
          this.mediaFileService.getfiledown(id).subscribe((data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = downloadURL;
            link.click();
          });
        }
      });
    }
  }
}
