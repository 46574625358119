import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ReportService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/reports', httpClient, toastrService);
  }

  downloadStartupCertificate() {
    debugger
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    // let query = {
    //   id: id
    // }

    return this.httpClient.get(this.baseUrl + 'api/reports/download-startup', httpOptions)
    .pipe(catchError(this.handleError.bind(this)));


    // return this.httpClient.get(this.baseUrl + 'api/reports/download-startup' + '?' + this.toQueryString(query), httpOptions)
    //   .pipe(catchError(this.handleError.bind(this)))
  }

  downloadNodalCertificate() {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(this.baseUrl + 'api/reports/download-nodal', httpOptions)
      .pipe(catchError(this.handleError.bind(this)));
  }

  downloadMentorCertificate() {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(this.baseUrl + 'api/reports/download-mentor', httpOptions)
      .pipe(catchError(this.handleError.bind(this)));
  }
  downloadInvestorCertificate() {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get(this.baseUrl + 'api/reports/download-investor', httpOptions)
      .pipe(catchError(this.handleError.bind(this)));
  }
  
  downloadStartupregCertificate() {
    debugger
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    // let query = {
    //   id: id
    // }

    return this.httpClient.get(this.baseUrl + 'api/reports/download-startupregistration' ,  httpOptions)
      .pipe(catchError(this.handleError.bind(this)))
  }

}