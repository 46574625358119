import { AuthService } from './../../../api/services/auth.service';
import { IChangePassword } from './../../../_models/account/user';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EncryptionChangePasswordService } from 'src/app/api/services/encryption-change-passwordService.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  loading: boolean;
  changePassword: IChangePassword;
  changePasswordForm: FormGroup;

  constructor(
    private authService: AuthService, 
    private toastrService: ToastrService, 
    private  encryptionService: EncryptionChangePasswordService,
    private fb: FormBuilder
    ) { }

  ngOnInit() {
    this.createChangePasswordForm();
  }

  save() {
    if (this.changePasswordForm.valid) {
      this.loading = true;
      const encryptedPassword = this.encryptionService.encrypt(this.changePasswordForm.value.oldPassword);
      const encryptedNewPassword = this.encryptionService.encrypt(this.changePasswordForm.value.newPassword);
      const encryptedConfirmPassword = this.encryptionService.encrypt(this.changePasswordForm.value.confirmPassword);
        // Replace the plain text values with the encrypted values
        this.changePasswordForm.patchValue({
          oldPassword: encryptedPassword,
          newPassword: encryptedNewPassword,
          confirmPassword: encryptedConfirmPassword
        });
      this.changePassword = Object.assign({}, this.changePasswordForm.value);
      this.authService.changePassword(this.changePassword).subscribe(() => {
        this.toastrService.success('Change Password successful');
        this.changePasswordForm.reset();
      },
        (error) => console.log('HTTP Error', error),
        () => this.loading = false);
    }
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmPassword').value ? null : { 'mismatch': true };
  }

}
