import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NodalService extends DataService {

  constructor(httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, toastrService: ToastrService) {
    super(baseUrl + 'api/nodals', httpClient, toastrService);
  }

  getLogged() {
    return this.httpClient.get(this.baseUrl + 'api/nodals/logged')
      .pipe(catchError(this.handleError.bind(this)))
  }

  status(resource) {
    return this.httpClient.post(this.baseUrl + 'api/nodals/status', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  slecApprove(resource) {
    return this.httpClient.post(this.baseUrl + 'api/nodals/slec-approve', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getAllNodals() {
    return this.httpClient.get(this.baseUrl + 'api/nodals/all')
      .pipe(catchError(this.handleError.bind(this)))
  }
  getDisNodalsList(filter) {
    return this.httpClient.get(this.baseUrl +'api/nodals/disbursement-nodal-list/' +'?' +this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)));
  }
}